<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top">
        <div class="close-btn flex-center"><i class="fa fa-times"></i></div>
      </div>

      <div class="head">
          <b>Cart</b>
      </div>

        <div class="scroll">
            <div class="summary">
                <div style="text-align: center">Order Summary</div>

                <div class="summary-item flex">
                    <span>Business card</span>
                    <span>$10,000.00</span>
                </div>
                <div class="summary-item flex">
                    <span>Identity card</span>
                    <span>$10,000.00</span>
                </div>
                <div class="summary-item flex">
                    <span>Counter tops</span>
                    <span>$10,000.00</span>
                </div>
                <div class="summary-item flex">
                    <span>Stickers</span>
                    <span>$10,000.00</span>
                </div>
                <div class="summary-item flex">
                    <span>Shipping</span>
                    <span>$50.00</span>
                </div>
                <div class="summary-item flex">
                    <span>VAT</span>
                    <span>$5.00</span>
                </div>
                <div style="text-align: center">
                    <div>Total Amount</div>
                    <div>
                        <b>$2,005.00</b>
                    </div>
                </div>

            <div class="btn" style="text-align: center">
                <Button @click="$router.push('/orders/checkout')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Buy now"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  components: {
    Button
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      formData: {
        image: ''
      },
      img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/orders')
      }
    },
    clickBtn(){
        document.getElementById('profile-picture').click()
    },
    onFileChange(e){
        const files = e.target.files || e.dataTransfer.files;
        if(!files.length)
            return
        this.formData.image = files[0]
        this.createImage(files[0])
    },
    createImage(file) {
        // const image = new Image();
        const reader = new FileReader();
        reader.onload = (e) => {
            this.img = e.target.result;
        };
        reader.readAsDataURL(file)
    },
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.5);
      padding: 10px 0px;
    }

    .modal-content {
      height: 100%;
      width: 500px;
      background: #fff;
      color: #262626;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000035;
      /* margin-top: 10rem; */
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 10px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .body h3 {
        margin-bottom: 20px;
    }

    .body > div {
        margin-bottom: 20px;
    }

    .head, .summary {
        padding: 0 2rem;
    }

    .head {
        margin-bottom: 30px;
    }

    .summary > div {
        margin-bottom: 30px;
    }

    .btn {
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .scroll {
        height: 80%;
        overflow: auto;
    }

    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .scroll::-webkit-scrollbar-thumb {
        background-color: #d3d3d3;
        outline: none;
    }
</style>